<template>
  <div class="p-6 pr-6 all">
    <popupCharge
      :activation="charge"
      v-if="charge"
    />
    <success
      :activation="activeSuccess"
      v-if="activeSuccess"
      :code="code"
      :message="message"
      @oga="backSuccess"
    />
    <div>
      <div
        class="flex items-center"
      >
        <div class="w-1/2 flex text-left text-c22 items-center font-c8">
          <icon
            :data="icons.back"
            height="40"
            width="40"
            class="mr-4 cursor-pointer"
            original
            @click="goBack"
          />
          <div class="">
            Créer un accès
          </div>
        </div>
      </div>

      <div class="flex justify-center">
        <div class="mt-6 bg-white p-12 w-1/2">
          <div class="flex justify-center">
            <div class="w-100">
              <upload
                height="100px"
                background="#D7E0EA"
                radius="100px"
                border="null"
                :icon="icons.user"
                icon-height="60"
              />
            </div>
          </div>

          <div class="mt-6">
            <div class="w-full">
              <div class="text-left text-83 text-c14">
                Nom
              </div>
              <inputo
                class="w-full mt-2"
                height="46px"
                radius="8px"
                size="16px"
                border="1px solid #EDEDEE"
                @info="backAdminFirstName"
              />
            </div>

            <div class="w-full mt-6">
              <div class="text-left text-83 text-c14">
                Prenom
              </div>
              <inputo
                class="w-full mt-2"
                height="46px"
                radius="8px"
                size="16px"
                border="1px solid #EDEDEE"
                @info="backAdminLastName"
              />
            </div>

            <div class="text-left text-83 text-c14 mt-6">
              Téléphone
            </div>
            <phoneNumber
              class="w-full mt-2"
              height="46px"
              radius="8px"
              size="16px"
              border="1px solid #EDEDEE"
              @info="backPhone"
            />

            <div class="text-left text-83 text-c14 mt-6">
              Email
            </div>
            <inputo
              class="w-full mt-2"
              height="46px"
              radius="8px"
              size="16px"
              border="1px solid #EDEDEE"
              type="email"
              @info="backAdminEmail"
            />


            <div class="text-left text-83 text-c14 mt-6">
              Organisation
            </div>
            <multiselect
              v-if="allSfd.length > 0"
              :option="dataSfd"
              @info="backSfd"
              :value-t="valueOrganisation"
              class="mt-2"
              height="46px"
              radius="8px"
              size="16px"
              border="1px solid #EDEDEE"
            />

            <div class="text-left text-83 text-c14 mt-6">
              Droits du compte
            </div>
            <multiselect
              :option="dataAccess"
              @info="backAccess"
              :is-multiple="true"
              :value-t="[]"
              class="mt-2"
              height="auto"
              radius="8px"
              size="16px"
              border="1px solid #EDEDEE"
            />

            <div
              v-if="error"
              class="text-center text-red mt-10"
            >
              {{ error }}
            </div>

            <div class="mt-16">
              <buton
                label="Enregistrer"
                height="51px"
                weight="700"
                size="18px"
                radius="6px"
                background="#31B049"
                class="w-full"
                @info="verification"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import user from '../../assets/icons/ic-clientAv.svg'
import upload from '../../component/helper/form/upload2'
import inputo from '../../component/helper/form/input'
import multiselect from '../../component/helper/form/multiselect'
import phoneNumber from '../../component/helper/form/phoneNumber'
import buton from '../../component/helper/add/button'
import apiroutes from '../../router/api-routes'
import http from '../../plugins/https'
import popupCharge from '../../component/popup/popupCharge'
import success from '../../component/popup/success'

export default {
  name: "Index",

  components: {
    buton,
    inputo,
    upload,
    phoneNumber,
    popupCharge,
    success,
    multiselect
  },

  data () {
    return {
      recherche: null,
      icons: {
        back,
        user
      },
      response: {
        role: [],
        pictureId: "",
        phoneNumber: null
      },
      charge: false,
      error: null,
      activeSuccess: false,
      message: 'Nouveau compte admin enregistré avec succès',
      dataSfd: ['Kasua'],
      dataAccess: ['Tableau de bord', 'Commerces', 'Coupons', 'Support client', 'Commerciaux', 'SFD partenaires', 'Droits d’accès'],
      allSfd: [],
      code: null,
      valueOrganisation: 'Kasua'
    }
  },

  mounted () {
    if (this.$store.getters.token !== null) {
      this.user = this.$store.getters.trackUser
      if (this.user.admin.sfdId){
        this.dataSfd = []
      }
      // console.log('admin session')
      // console.log(this.user)
    }
    this.getAllSfd()
  },

  methods: {
    backSuccess (a) {
      this.activeSuccess = false
      this.$router.push('droits')
    },

    backAccess (a) {
      this.response.role = a
    },

    backFile (a) {
      // this.response.file = a
    },

    backPhone (a) {
      if (a && a !== '') {
        this.response.phoneNumber = a
      }
    },

    backAdminFirstName (a) {
      this.response.firstName = a
    },

    backAdminLastName (a) {
      this.response.lastName = a
    },

    backAdminEmail (a) {
      this.response.email = a
    },

    goBack () {
      window.history.back()
    },

    verification () {
      if (this.response.firstName &&
          this.response.lastName &&
          this.response.email

      ) {
        if (this.response.sfdId) {
          this.saveSfdAdmin()
        } else {
          this.saveAdmin()
        }
      } else {
        this.error = 'Remplissez les champs du formulaire pour continuer'
      }
    },

    saveAdmin () {
      this.charge = true
      http.post(apiroutes.baseURL + apiroutes.createAdmin, this.response)
          .then(response => {
            // console.log(response)
            this.charge = false
            this.activeSuccess = true
          })
          .catch(error => {
            this.charge = false
            // console.log(error)
          })
    },

    saveSfdAdmin () {
      this.charge = true
      http.post(apiroutes.baseURL + apiroutes.createSfdAdmin, this.response)
          .then(response => {
            // console.log(response)
            this.charge = false
            this.activeSuccess = true
          })
          .catch(error => {
            this.charge = false
            // console.log(error)
          })
    },

    backSfd (a) {
      if (a !== 'Kasua') {
        for (let i in this.allSfd) {
          if (this.allSfd[i].sfd.name === a) {
            this.response.sfdId = this.allSfd[i].sfd.id
          }
        }
      }
    },

    getAllSfd () {
      http.get(apiroutes.baseURL + apiroutes.allSfd)
          .then(response => {


            if (this.user.admin.sfdId) {
              // lorsque je suis un admin sfd
              for (let i in response) {
                if (response[i].sfd.id === this.user.admin.sfdId){
                  this.valueOrganisation = response[i].sfd.name
                  this.dataSfd.push(response[i].sfd.name)
                }
              }
            } else {
              // lorsque je suis un admin général
              for (let i in response) {
                this.dataSfd.push(response[i].sfd.name)
              }
            }
            this.allSfd = response
            // console.log(response)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            // console.log(error)
          })
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.all{
  min-height: 100vh;
}
</style>
